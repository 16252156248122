import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
import { token } from "./helpers";

//pass new generated access token here
// const token = accessToken;

//apply base url for axios
const API_URL = process.env.REACT_API_URL || "https://api.seduss.com";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = 'Bearer ' + token || accessToken;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => throwError(error)
);

export const apiURL = (url='') => (API_URL + url);
export const fileURL = (id) => (API_URL + '/file/fileLink/' + id);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

const throwError = err  => {
    let message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Üzgünüm! Aradığınız sayfa bulunumadı.";
          break;
        case 500:
          message =
            "Üzgünüm! Bir şeyler ters gitti, lütfen destek ekibimizle iletişime geçin.";
          break;
        case 401:
        case 406:
          message = "Kimlik bilgileri geçersizdir.";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
}

export async function uploadFile(file, config = {}) {
  const formData = new FormData();
  formData.append("file", file);
  return await axiosApi
    .post("/file", formData, { ...config })
    .then((response) => response.data);
}