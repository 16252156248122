import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  Modal,
  FormGroup,
} from "reactstrap";

//redux
import { useSelector, useDispatch, connect } from "react-redux";
import { createSelector } from "reselect";
import PropTypes from "prop-types"



import user1 from "../../assets/images/users/user-1.jpg";
// actions
import { setBreadcrumbItems } from "../../store/actions";
import { apiURL, get, fileURL, put, uploadFile } from "helpers/api_helper";
import userRoles from "helpers/userRoles";
import { useAlert } from "providers/alertProvider";
import XModal from "components/Custom/XModal";
import X from "components/Custom/X";

const UserProfile = props => {

  //meta title
  document.title = props.title || "Profil";

  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [surname, setsurname] = useState("");
  const [profilename, setprofilename] = useState("");
  const [idx, setidx] = useState(1);
  const [user, setuser] = useState({});
  const [userprofile, setuserprofile] = useState({});
  const [role, setrole] = useState('');
  const [openAlert, closeAlert] = useAlert();
  const [photoModal, setPhotoModal] = useState(false);
  const [useProfilePhotoInput, setProfilePhotoInput] = useState(null);
  const [useProfileImage, setProfileImage] = useState('');

  const selectProfileState = (state) => state.Profile;
    const ProfileProperties = createSelector(
      selectProfileState,
        (profile) => ({
          error: profile.error,
          success: profile.success,
        })
    );

  const {
    error,
    success
  } = useSelector(ProfileProperties);

  const handleFormSubmit = () => {
    const data = {
      firstName: name,
      lastName: surname,
    };
    put(`/profile/${userprofile._id}`, data).then((response) => {
      if(response) openAlert("Profil başarıyla güncellendi");
      else openAlert("Profil güncellenirken bir hata oluştu", "error");
    });
  };

  const toggleModal = () => {
    setPhotoModal(!photoModal);
  };

  const handleProfilePhotoChange = (e) => {
    setProfilePhotoInput(e.target);
  }

  const handleProfilePhotoSubmit = () => {
    if(!useProfilePhotoInput) {
      openAlert("Lütfen bir dosya seçin", "error");
    } else {
      const file = useProfilePhotoInput.files[0];
      uploadFile(file).then((response) => {
        const fileId = response._id;
        put(`/user/${user._id}`, { image: fileId }).then((userResponse) => {
          getUser();
          toggleModal();
          openAlert("Profil fotoğrafı başarıyla güncellendi");
        });
      });
    }
  }

  const getUser = () => {
    get("/user/me").then((response) => {
      if (response) {
        setuser(response);
        setProfileImage(fileURL(response.image));
        get("/profile/byUser/" + response._id).then((response) => {
          if (response) {
            setuserprofile(response);
            setprofilename(response.firstName + " " + response.lastName);
          }
        });
      }
    });
  };

  useEffect(() => {
    setemail(user.email);
    setrole(user.role);
    setProfileImage(fileURL(user.image));
  }, [user]);

  useEffect(() => {
    setname(userprofile.firstName);
    setsurname(userprofile.lastName);
  }, [userprofile]);

  useEffect(() => {
    props.onSetBreadCrumbs('Profil', [
      { title: "Anasayfa", link: "/" },
      { title: "Profil", link: "#" },
    ]);
    getUser();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>
          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={useProfileImage}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                      <div>
                        <Button onClick={toggleModal}>Değiştir</Button>
                      </div>
                      
                      <XModal title="Profil Fotoğrafı" showModal={photoModal} toggle={toggleModal} onSubmit={handleProfilePhotoSubmit}>
                        <FormGroup>
                          <Label for="profilePhoto">
                            Resim
                          </Label>
                          <Input
                            id="profilePhoto"
                            name="file"
                            type="file"
                            accept="image/*"
                            onChange={handleProfilePhotoChange}
                          />
                        </FormGroup>
                      </XModal>

                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{profilename}({userRoles[role]})</h5>
                        <p className="mb-1">{email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Kullanıcı Bilgileri</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleFormSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label for="name" className="form-label">İsim</Label>
                  <Input
                    name="name"
                    className="form-control"
                    placeholder="İsminiz"
                    type="text"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <Label for="name" className="form-label">Soyisim</Label>
                  <Input
                    name="surname"
                    className="form-control"
                    placeholder="Soyisminiz"
                    type="text"
                    value={surname}
                    onChange={(e) => setsurname(e.target.value)}
                  />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Güncelle
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
    onSetBreadCrumbs: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    title: state.Layout.title,
  };
};

const mapDispatchToProps = (dispatch) => ({
    onSetBreadCrumbs: (title, breadcrumb) => dispatch(setBreadcrumbItems(title, breadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

