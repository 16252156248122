import React from 'react';
import { AlertProvider } from './alertProvider';

const Providers = props => {
  return (
    <>
      <AlertProvider>
        {props.children}
      </AlertProvider>
    </>
  );
}

export default Providers;
