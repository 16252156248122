import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap";

const XModal = props => {
  const { 
    title, 
    showModal=false, 
    toggle, 
    titleBar=true, 
    buttons=true, 
    saveButton=true, 
    onSubmit,
  } = props

  const [useShowModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(showModal)
  }, [showModal]);
  
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleModal() {
    setShowModal(!useShowModal)
    toggle();
    removeBodyCss()
  }

  return (
    <Modal
      isOpen={useShowModal}
      toggle={() => {
        toggleModal()
      }}
      centered={true}
    >
      { titleBar &&
      <div className="modal-header">
        <h5 className="modal-title mt-0">{title}</h5>
        <button
          type="button"
          onClick={() => {
            setShowModal(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      }
      
      <div className="modal-body">
        {props.children}
      </div>

      { buttons && 
      <div className="modal-footer">
        {saveButton && <button type="button" className="btn btn-primary" onClick={onSubmit}>
          Kaydet
        </button> }
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={() => toggleModal()}
        >
          Kapat
        </button>
      </div>
      }
    </Modal>
  )
}

export default XModal;
